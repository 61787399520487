<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div id="contedit-content" class="content-header px-0">
      <h1 id="contedit-edit-title">Editar contato</h1>
    </div>
    <div id="contedit-div-master" class="col-12 col-sm-12 p-0 col-md-12">
      <div id="contedit-div-card" class="card direct-chat direct-chat-primary">
        <div id="contedit-div-card-body" class="card-body">
          <div id="contedit-form-wrapper" class="p-4 bg-white rounded">
            <form
              id="contedit-edit-form"
              @submit="editContact"
              novalidate="true"
            >
              <div id="contedit-wrapper-row" class="row">
                <GenInput id="contedit-twin-name" label="Nome" required="false">
                  <input
                    id="nomeCotato"
                    v-model="contactEdit.name"
                    maxlength="50"
                    required
                    type="text"
                    class="form-control"
                    placeholder="Nome"
                  />
                </GenInput>

                <GenInput
                  id="contedit-twin-tel"
                  label="Telefone"
                  required="false"
                >
                  <input
                    id="telefoneContato"
                    v-model="contactEdit.phones[0]"
                    v-mask="'+(##) ## #####-####'"
                    required
                    type="text"
                    class="form-control"
                    placeholder="Telefone"
                  />
                  <small id="contedit-tel-ex" class="form-text text-muted"
                    >Ex: +(55) 41 99999-9999</small
                  >
                </GenInput>
              </div>

              <div id="contedit-save-wrapper" class="row mt-2 p-1">
                <button
                  id="saveContato"
                  class="btn btn-primary px-5"
                  :disabled="load"
                >
                  <i
                    id="contedit-load-icon"
                    v-if="load"
                    class="fas fa-sync-alt fa-spin"
                  ></i>
                  Salvar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import GenInput from "@/components/GenInput.vue";
export default {
  name: "EditContact",
  data: function () {
    return {
      breadcrumbList: [],
      contactEdit: {
        id: "",
        name: "",
        phones: [],
      },
    };
  },
  mounted() {
    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });
    } else {
      window.frontMsg(
        "FRNT_MISSING_WARNING",
        { frontelement: "do Cliente" }
      );
      this.$router.push("/");
    }

    if (this.$route.params.contactId) {
      this.$store.dispatch("contact/getContact", {
        contactId: this.$route.params.contactId,
      });
    } else {
      window.frontMsg(
        "FRNT_MISSING_WARNING",
        { frontelement: "do Contato" }
      );
      this.$router.push("/");
    }

    this.updateList();
  },
  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if (this.customer) {
        this.breadcrumbList[1].label = `Editar cliente ${this.customer.company_name}`;
      }
    },
    isContactValid(contact) {
      if (!contact.name || contact.name.trim() === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Nome do Contato" }
        );
        return false;
      }
      if (!contact.phones) {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Número de Telefone" }
        );
        return false;
      }
      let phone = contact.phones.map((phone) => phone.replace(/[\s()-]/g, ""));
      if (!phone || phone.length < 1 || phone[0].trim() === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Número de Telefone" }
        );
        return false;
      }
      else if (phone[0].length < 13 || phone[0].length > 14) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "Número de Telefone" }
        );
        return false;
      }
      return true;
    },
    editContact() {
      event.preventDefault();
      if (!this.isContactValid(this.contactEdit)) {
        return;
      }

      this.$store.dispatch("contact/updateContact", {
        contact: {
          ...this.contactEdit,
          phones: [this.contactEdit.phones[0].replace(/[\s()-]/g, "")],
        },
        customer: this.customer,
      });
    },
  },
  watch: {
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    customer() {
      if (this.customer) {
        this.updateList();
      }
    },
    contact() {
      if (this.contact) {
        this.contactEdit.id = this.contact.id;
        this.contactEdit.name = this.contact.name;
        this.contactEdit.phones = this.contact.phones;
      }
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    contact() {
      return this.$store.getters["contact/contact"];
    },
  },
  components: {
    Breadcrumb,
    GenInput,
  },
};
</script>
