/* eslint-disable vue/script-setup-uses-vars */
<template>
  <div id="confreset-login-body" class="login-body font">
    <div id="confreset-container-login" class="container-login">
      <div id="confreset-first-con" class="content first-content">
        <div id="confreset-first-col" class="first-column">
          <img
            id="confreset-logo"
            :src="require('../../assets/img/Logo3.png')"
            style="width: 50%"
          />
          <p id="confreset-desc-p" class="description description-primary">
            (Seu ideal)
          </p>
          <div>
            <router-link to="/login" id="confreset-back-a">
              <button id="confreset-back-but" class="btn-login btn-login-primary">Voltar</button>
            </router-link>
          </div>
        </div>
        <div id="confreset-div-sec-col" class="second-column">
          <h2 id="confreset-redef-title" class="title title-second">Redefinir senha</h2>
          <p id="confreset-description-second" class="description description-second">
            Uma mensagem com um código de verificação <br />
            <br />
            foi enviada por email e SMS.
          </p>
          <form id="confreset-redef-form" class="form login-box mt-3 card" @submit="reset">
            <label id="confreset-label-redef" class="label-input" for="">
              <i id="fname" class="fas fa-envelope-open-text icon"></i>
              <input
                id="confreset-code-input"
                required
                name="codigo"
                type="text"
                class="form-control formzinho"
                placeholder="Código"
                v-model="code"
                style="height: 45px; width: 100%"
              />
            </label>
            <div id="confreset-form-gp-show" class="form-group pass-show">
              <label id="confreset-label-for-show" class="label-input" for="">
                <i id="confreset-lock-i" class="fas fa-lock icon"></i>
                <input
                  id="confreset-pass-input"
                  placeholder="Senha"
                  class="formzinho form-control"
                  required
                  v-model="new_password"
                  :type="!passShow ? 'password' : 'text'"
                  style="height: 45px; width: 100%"
                />
                <a id="confreset-showpass-a" @click="showPass" href="/" class="icon2">
                  <i
                    id="confreset-open-eyes"
                    v-bind:class="{
                      'far fa-eye': passShow,
                      'far fa-eye-slash': !passShow,
                    }"
                  ></i>
                </a>
              </label>
            </div>
            <button id="confreset-enter-but" class="btn-login btn-login-second">entrar</button>
            <div id="confreset-load-over" v-if="load" class="overlay text-center">
              <i id="confreset-load-spin" class="fas fa-sync-alt fa-spin"></i>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmReset",
  data: function () {
    return {
      passShow: false,
      code: "",
      new_password: "",
    };
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    year: () => {
      const date = new Date();
      return date.getFullYear();
    },
  },
  methods: {
    showPass() {
      event.preventDefault();
      this.passShow = !this.passShow;
    },
    reset() {
      event.preventDefault();
      this.$store.dispatch("login/confirmReset", {
        code: this.code,
        new_password: this.new_password,
      });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap");
</style>
