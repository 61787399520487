<template>
  <div class="Customer">
    <div class="row">
      <GenInput label="Nome" required="false">
        <input
          id="editnamecustomer"
          v-model="customerEdit.company_name"
          required
          type="text"
          maxlength="50"
          class="form-control"
          placeholder="Nome do cliente"
          :disabled="!hasRole(['ADMIN', 'SYSADMIN', 'MANAGER'])"
        />
      </GenInput>

      <GenInput label="CNPJ" required="false">
        <input
          id="editcnpjcustomer"
          v-model="customerEdit.cnpj"
          required
          v-mask="'##.###.###/####-##'"
          type="text"
          class="form-control"
          placeholder="CNPJ"
          :disabled="!hasRole(['ADMIN', 'SYSADMIN', 'MANAGER'])"
        />
      </GenInput>
    </div>

    <div class="row">
      <GenInput label="Grupo" required="false">
        <select
          v-model="groupsSelecteds"
          multiple
          required
          class="form-control p-0"
          :disabled="!hasRole(['ADMIN', 'SYSADMIN', 'MANAGER'])"
          id="groupsSelectedsctab"
        >
          <option
            v-for="(group, index) in groups"
            :key="index"
            class="p-2"
            :value="group.name"
          >
            {{ group.name }}
          </option>
        </select>
      </GenInput>
      <div class="col-md-6">
        <GenInput label="Time Zone" required="false" placeholder="Ex: +0300" >
          <select id="timezones" required class="form-control" v-model="timezone" :disabled="!hasRole(['ADMIN', 'SYSADMIN', 'MANAGER'])">
            <option value="+1200">UTC +12 Horário Padrão Da Nova Zelândia  </option>
            <option value="+1100">UTC +11 Horário Padrão De Sacalina </option>                   
            <option value="+1000">UTC +10 Horário Padrão Da Austrália Oriental  </option>
            <option value="+0900">UTC +9  Horário Padrão Do Japão</option>
            <option value="+0800">UTC +8  Horário Padrão Da Austrália Ocidental</option>                     
            <option value="+0700">UTC +7  Horário Da Indochina</option>
            <option value="+0600">UTC +6  Horário Do Oceano Índico </option>
            <option value="+0500">UTC +5  Horário Padrão Do Paquistão </option>                    
            <option value="+0400">UTC +4  Horário Do Golfo </option>
            <option value="+0300">UTC +3  Horário Padrão De Moscou </option>
            <option value="+0200">UTC +2  Horário Da África Do Sul</option>
            <option value="+0100">UTC +1  Horário Padrão Da Europa Central </option>
            <option value="+0000">UTC +0  Horário Do Meridiano De Greenwich </option>                     
            <option value="-0100">UTC -1  Horário Padrão Do Cabo Verde</option>
            <option value="-0200">UTC -2  Horário Da Geórgia Do Sul </option>
            <option value="-0300">UTC -3  Horário Padrão De Brasília</option>
            <option value="-0400">UTC -4  Horário Padrão Do Chile</option>
            <option value="-0500">UTC -5  Horário Do Equador </option>
            <option value="-0600">UTC -6  Horário De Galápagos</option>
            <option value="-0700">UTC -7  Horário Padrão Da Montanha </option>
            <option value="-0800">UTC -8  Horário Padrão Do Pacífico</option>
            <option value="-0900">UTC -9  Horário Padrão Do Alasca</option>
            <option value="-1000">UTC -10  Horário Do Taiti </option>
            <option value="-1100">UTC -11  Horário Padrão De Samoa </option>
              {{timezone}}
          </select>
        </GenInput>
      </div>
    </div>
    <div class="row p-1">
      <button
        @click="updateCustomer"
        id="saveeditcustomer"
        class="btn btn-primary px-5"
        v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
        :disabled="load"
      >
        <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
        Salvar
        <i class="fas fa-save ml-2"></i>
      </button>
    </div>
  </div>
</template>

<script>
import GenInput from "@/components/GenInput.vue";
import { validCnpj } from "@/services/helpers";
import hasRole from "@/utils/hasRole";
import "./styles.scss";

export default {
  components: {
    GenInput,
  },
  data() {
    return {
      name: "",
      timezone: "",
      groupsSelecteds: [],
      customerEdit: {
        group: {
          name: "",
          cnpj: "",
          company_name: "",
          id: "",
        },
      },
    };
  },
  mounted() {
    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });
    } else {
      window.frontMsg(
        "FRNT_MISSING_WARNING",
        { frontelement: "do Cliente" }
      );
      this.$router.push("/");
    }

    this.$store.dispatch("customer/get");
    this.$store.dispatch("customer/getGroups");
  },
  methods: {
    hasRole,
    validCnpj,
    isCustomerValid(customer) {
      customer.cnpj = customer.cnpj.replace(/\s/g, "");
      
      if (this.timezone.length > 5 || this.timezone.length < 5) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "Fuso Horário" }
        );
        return false;
      }
      if (this.timezone[0] != "+" && this.timezone[0] != "-") {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "Fuso Horário", extra: "O Fuso Horário deve começar com + ou -" }
        );
        return false;
      }
      if (this.timezone[1] + this.timezone[2] > 23) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "Fuso Horário", extra: "A hora máxima permitida é 23" }
        );
        return false;
      }
      if (this.timezone[3] + this.timezone[4] > 59) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "Fuso Horário", extra: "O minuto máximo permitido é 59" }
        );
        return false;
      }
      if (!customer.company_name || customer.company_name.trim() === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Nome" }
        );
        return false;
      }

      if (customer.cnpj.length < 18) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "CNPJ" }
        );
        return false;
      }

      let checkCnpj = (obj) =>
        obj.cnpj === customer.cnpj && obj.id !== customer.id;

      
      if (this.customers.some(checkCnpj)) {
        window.frontMsg(
          "FRNT_IN_USE_WARNING",
          { field: "CNPJ" }
        );
        return false;
      }

      if (customer.cnpj && this.validCnpj(customer.cnpj) === false) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "CNPJ" }
        );
        return false;
      }

      if (
        !this.groupsSelecteds ||
        this.groupsSelecteds.length !== 1 ||
        this.groupsSelecteds[0].trim() === ""
      ) {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Grupo" }
        );
        return false;
      }

      return true;
    },
    updateCustomer() {
      if (!this.isCustomerValid(this.customerEdit)) {
        return;
      }
      this.$store.dispatch("customer/updateCustomer", {
        customer: {
          ...this.customerEdit,
          timezone: this.timezone,
          group: {
            name: this.groupsSelecteds[0],
          },
        },
      });
    },
  },
  watch: {
    groupsSelecteds() {
      if (this.groupsSelecteds.length > 1)
        this.groupsSelecteds = [this.groupsSelecteds.slice(-1).pop()];
    },
    customer() {
      this.customerEdit.group.name = this.customer.group?.name ?? "";
      this.customerEdit.cnpj = this.customer.cnpj ?? "";
      this.customerEdit.company_name = this.customer.company_name ?? "";
      this.customerEdit.id = this.customer.id ?? this.$route.params.id;
      this.groupsSelecteds = [this.customer.group?.name ?? ""];
      this.timezone = this.customer.timezone ?? "";
    },
  },
  computed: {
    groups() {
      return this.$store.getters["customer/groups"];
    },
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    customers() {
      return this.$store.getters["customer/customers"];
    },
  },
};
</script>

<style lang="scss" scoped></style>
