<template>
  <div>
    <div id="dashindx-content" class="content-header px-0">
      <h1 id="dashindx-dash-title" class="title-I">Dashboards Power BI</h1>
    </div>

    <div id="dashindx-row" class="row">
      <GenInput id="dashindx-tw-inpt" v-show="groups.length > 1" col_lg="4" label="Grupo">
        <select id="dashindx-selectgp" class="form-control" v-model="selectedGroup">
          <option id="dashindx-null-option" :value="null" selected>Selecione um Grupo</option>
          <option v-for="group in groups" :value="group" :key="group" id="group-option">
            {{ group }}
          </option>
        </select>
      </GenInput>

      <GenInput
        id="dashindx-twin" col_lg="4"
        label="Workgroup"
        v-show="workgroups.length > 1"
      >
        <select
          id="dashindx-select-wkgp"
          class="form-control"
          v-model="selectedWorkgroup"
          :disabled="!selectedGroup"
        >
          <option id="dashindx-null-option" :value="null" selected>Selecione um Workgroup</option>
          <option
            v-for="workgroup in possibleWorkgroups"
            :id="workgroup.name"
            :value="workgroup"
            :key="workgroup.name"
          >
            {{ workgroup.name }}
          </option>
        </select>
      </GenInput>
      <GenInput id="dashindx-twin-type" col_lg="4" label="Tipo">
        <select id="dashindx-select-filter" class="form-control" v-model="filterType">
          <option id="dashindx-option-null" :value="null" selected>Todos</option>
          <option id="dashindx-option-reports" value="reports">Reports</option>
          <option id="dashindx-option-dash" value="dashboards">Dashboards</option>
        </select>
      </GenInput>
    </div>

    <b-table
      id="dashindx-pbifiltered-table"
      responsive="sm"
      striped
      borderless
      hover
      class="mt-0 rounded"
      no-sort-reset
      ref="selectableTable"
      sticky-header="610px"
      show-empty
      empty-text="Nenhum registro encontrado"
      empty-filtered-text="Nenhum registro encontrado para essa busca"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      @context-changed="changeBsTableState"
      :busy="load_pbi"
      :filter="filter"
      :fields="fields"
      :items="filteredPbiItems"
    >
      <template v-slot:table-busy>
        <div id="dashindx-pbi-table-busy" class="text-center my-2">
          <strong>
            <i id="dashindx-pbi-table-spin" class="fas fa-sync-alt fa-spin"></i>
          </strong>
        </div>
      </template>

      <template v-slot:table-colgroup>
        <col
          v-for="field in fields"
          :id="'dashindx-col-'+field.key"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '100px' : '550px' }"
        />
      </template>
      <template v-slot:cell(embed_url)="data">
        {{ data.item.embed_url.includes("dashboard") ? "Dashboard" : "Report" }}
      </template>
      <template v-slot:cell(actions)="data">
        <a
          id="excluirgroup"
          @click="
            openIndicator(
              data.item.id,
              data.item.embed_url,
              data.item.workgroup_id
            )
          "
          href="#"
          class="mr-3"
          title="Abrir o indicador"
        >
          <i id="dashindx-exter-link-i" class="fas fa-external-link-alt"></i>
        </a>
      </template>
    </b-table>
    <div id="dashindx-div-row2" class="row">
      <div id="dashindx-div-ccol" v-if="rows" class="col-12 col-md-4 mb-2 pt-1">
        <b-pagination
          id="dashindx-pagination"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="pagination"
        ></b-pagination>
      </div>
      <div id="dashindx-div-col12-8" class="col-12 col-md-8">
        <div id="dashindx-pagebtns" class="btn-group" role="group">
          <b-dropdown
            id="dashindx-lines1" variant="primary"
            v-model="perPage"
            :text="`Linhas: ${perPage}`"
          >
            <b-dropdown-item
              id="dashindx-lines2" href="#"
              @click="
                () => {
                  this.perPage = 10;
                }
              "
              >10</b-dropdown-item
            >
            <b-dropdown-item
              id="dashindx-lines3" href="#"
              @click="
                () => {
                  this.perPage = 20;
                }
              "
              >20</b-dropdown-item
            >
            <b-dropdown-item
              id="dashindx-lines4" href="#"
              @click="
                () => {
                  this.perPage = 50;
                }
              "
              >50</b-dropdown-item
            >
            <b-dropdown-item
              id="dashindx-lines5" href="#"
              @click="
                () => {
                  this.perPage = '1000';
                }
              "
              >1000</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </div>

    <b-modal
      id="dashboard-modal"
      lazy
      size="xl"
      v-model="modalShow"
      hide-footer
    >
      <template #modal-header="{ close }">
        <div id="dashindx-modal-wrap" class="w-100 row d-flex justify-content-between">
          <h5>
            <img id="dashindx-pbi-img" src="@/assets/img/pbi.png" width="25px" class="mr-3" />
            Visualizando conteúdo do Power BI
          </h5>
          <div>
            <button
              id="dashindx-fullscreen-load"
              @click="fullcreen"
              type="button"
              class="btn btn-primary btn-sm"
              v-if="selectedType === 'report'"
            >
              <i id="dashindx-expand-i" class="fas fa-expand pr-3"></i>
              Tela cheia
            </button>
            <b-button id="dashindx-close-modal" variant="link" @click="close()">
              <span id="dashindx-x" >X</span>
            </b-button>
          </div>
        </div>
      </template>
      <OpenIndicator
        v-if="this.email"
        :type="selectedType"
        :id="selectedId"
        :workgroup_id="selectedWorkgroupId"
        :email="this.email"
        :embedUrl="selectedEmbedUrl"
        ref="indicator"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GenInput from "@/components/GenInput";
import OpenIndicator from "@/components/openIndicator.vue";

export default {
  components: {
    OpenIndicator,
    GenInput,
  },
  data: function () {
    return {
      perPage: 10,
      currentPage: 1,
      rows: 1,
      sortBy: "",
      breadcrumbList: [],
      floorDelete: "",
      show_group: false,
      workgroup: null,
      selectedGroup: null,
      selectedWorkgroup: null,
      possibleWorkgroups: [],
      fields: [
        { key: "name", sortable: true, label: "Nome" },
        { key: "embed_url", sortable: true, label: "Tipo" },
        { key: "actions", label: "Ações", class: "actions" },
      ],
      modalShow: false,
      selectedType: null,
      selectedId: null,
      selectedEmbedUrl: null,
      selectedWorkgroupId: null,
      filter: null,
      filterType: null,
      filteredPbiItems: [],
      email: null,
      load_pbi: true,
    };
  },
  created() {
    this.email = localStorage.email ? localStorage.email : sessionStorage.email;
    if (this.email !== null) {
      this.$store.dispatch("dashboard/getPBIItems", {
        email: this.email,
      });
      this.$store.dispatch("dashboard/getGroupsWorkgroups", {
        email: this.email,
      });
    } else {
      this.$store.commit("dashboard/SET_DASHBOARDS", []);
      this.$store.commit("dashboard/SET_REPORTS", []);
    }
    this.filterPBIItens();
  },
  watch: {
    groups() {
      if (this.groups.length == 1) {
        this.selectedGroup = this.groups[0];
      }
    },
    pbiItems() {
      this.load_pbi = false;
      this.filteredPbiItems = this.pbiItems;
      if (this.$options.name) {
        const ctx = JSON.parse(
          localStorage.getItem(`bstable-state-${this.$options.name}`)
        );
        if (!ctx){
          this.perPage = 10;
          this.currentPage = 1;
          this.sortBy = "";
          this.rows = this.pbiItems.length;
          return;
        }
        if (ctx.perPage) {
          this.perPage = ctx.perPage;
        }
        if (ctx.sortBy !== "") {
          this.sortBy = ctx.sortBy;
        }
        // IOT-873: Table indicates page 1, but the content is of the last page visited.
        /*if (ctx.currentPage) {
          this.currentPage = ctx.currentPage;
        }*/
      }
      this.rows = this.pbiItems.length;
    },
    selectedGroup() {
      this.selectedWorkgroup = null;
      this.possibleWorkgroups = this.workgroups.filter((item) => {
        return item.group == this.selectedGroup;
      });
      this.filterPBIItens();
    },
    selectedWorkgroup() {
      this.workgroup = this.selectedWorkgroup;
      this.filterPBIItens();
    },
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    filterType() {
      this.filterPBIItens();
    },
  },
  methods: {
    fullcreen() {
      this.$refs.indicator.fullscreen();
    },
    changeBsTableState(table_state) {
      if (this.$options.name) {
        localStorage.setItem(
          `bstable-state-${this.$options.name}`,
          JSON.stringify(table_state)
        );
      }
    },
    onFiltered(pbiItems) {
      this.rows = pbiItems.length;
    },
    changeWorkgroup(workgroup) {
      this.workgroup = workgroup;
    },
    async openIndicator(id, embed_url, workgroup_id) {
      this.selectedType = embed_url.includes("dashboard")
        ? "dashboard"
        : "report";
      this.selectedId = id;
      this.selectedEmbedUrl = embed_url;
      this.selectedWorkgroupId = workgroup_id;
      this.modalShow = true;
    },
    filterPBIItens() {
      this.filteredPbiItems = this.pbiItems.filter((item) => {
        const isGroupSelected = this.selectedGroup !== null;
        const isWorkgroupSelected = this.selectedWorkgroup !== null;
        const isTypeSelected = this.filterType !== null;

        const groupFilter = isGroupSelected
          ? item.group === this.selectedGroup
          : true;

        const workgroupFilter = isWorkgroupSelected
          ? item.workgroup === this.selectedWorkgroup.name
          : true;

        let typeFilter = true; // Default 'todos'
        if (isTypeSelected) {
          if (this.filterType === "reports") {
            typeFilter = item.embed_url.includes("report");
          } else if (this.filterType === "dashboards") {
            typeFilter = item.embed_url.includes("dashboard");
          }
        }
        return groupFilter && workgroupFilter && typeFilter;
      });

      this.rows = this.filteredPbiItems.length
    },
  },
  computed: {
    groups() {
      let groups = this.$store.getters["user/groups"];
      groups = groups.filter((group) => {
        return group.pbi === true && group.id !== null;
      });
      return groups;
    },
    pbiItems() {
      return [...this.reports, ...this.dashboards];
    },
    ...mapGetters({
      reports: "dashboard/reports",
      dashboards: "dashboard/dashboards",
      groups: "dashboard/groups",
      workgroups: "dashboard/workgroups",
      user: "user/user",
      load: "load",
    }),
  },
};
</script>

<style>
  #dashindx-tw-inpt, #dashindx-twin, #dashindx-twin-type{
    padding-right: 1.5 rem !important;
  }
</style>