ls
<template>
  <div
    id="total"
    class="login-body font"
    v-bind:class="{ signinjs: isActive, signupjs: isOk }"
  >
    <div class="container-login">
      <div class="container2">
        <div class="item"><h2>Sem acesso ao portal</h2></div>
        <div class="aviso">
          <p>
            Seu usuário não tem acesso ao portal, entre em contato com o
            administrador da plataforma.
          </p>
          <div class="bt">
            <router-link to="/login" id="goBack">
              <button id="nofront-back-btn" class="btn-login float-right">Voltar</button>
            </router-link>
          </div>
        </div>
        <img
          class="image"
          :src="require('../../assets/img/Logo3.png')"
          style="width: 300px"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoFrontend",
  data: function () {
    return {};
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container2 {
  display: flex;
  flex-direction: column;
  background-color: rgb(180, 33, 33);
  border-radius: 10px;
  width: 40%;
  height: 37%;
}
.item {
  color: white;
  text-align: center;
  font-family: Dosis, sans-serif !important;
  font-weight: bold;
  margin-bottom: 60px;
  margin-top: 25px;
  font-weight: bold;
  font-family: Days One, sans-serif !important;
  font-weight: bold;
}
.aviso {
  color: white;
  font-size: 110%;
  /* font-weight:500px; */
  margin: 1em;
  margin-right: 4em;
  margin-left: 4em;
  margin-bottom: 4em;
}
.bt {
  margin-top: 50px;
}
.image {
  position: absolute;
  right: 0px;
  width: 300px;
  margin-top: 450px;
  padding: 10px;
}
</style>
