import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import user from "./modules/user";
import customer from "./modules/customer";
import dashboard from "./modules/dashboard";
import login from "./modules/login";
import contact from "./modules/contact";
import notificationRule from "./modules/notificationRule";
import alert from "./modules/alert";
import token from "./modules/token";
import platform from "./modules/platform";
import asset from "./modules/asset";

export default new Vuex.Store({
  state: {
    load: false,
    showModalLogin: false,
    loadCounter: 0,
  },
  modules: {
    user,
    customer,
    dashboard,
    login,
    contact,
    notificationRule,
    alert,
    token,
    platform,
    asset,
  },
  getters: {
    load: (state) => state.loadCounter !== 0,
    showModalLogin: (state) => state.showModalLogin,
  },
  mutations: {
    CHANGE_LOAD(state, state_load) {
      state.load = state_load;
    },
    CHANGE_MODAL(state, state_modal) {
      state.showModalLogin = state_modal;
      var jQueryObj = Object.keys(document.querySelector("#modalLogin")).filter(
        (key) =>
          key.toString().indexOf("jQuery") !== -1 &&
          document.querySelector("#modalLogin")[key].hasOwnProperty("bs.modal")
      );

      document.querySelector("#modalLogin")[jQueryObj]["bs.modal"].show();
    },
    INCREMENT_LOAD(state){
      state.loadCounter++;
      window.loadCounter = state.loadCounter;
      state.load = true;
    },
    DECREMENT_LOAD(state){
      if (state.loadCounter === 0){
        state.load = false;
        window.loadCounter = 0;
      }
      else {
        state.loadCounter--;
        window.loadCounter = state.loadCounter;
      }
    },
  },
  actions: {
    changeLoad(context, state_load) {
      context.commit("CHANGE_LOAD", state_load);
    },
    changeModal(context, state_modal) {
      context.commit("CHANGE_MODAL", state_modal);
    },
    incrementLoad(context){
      context.commit("INCREMENT_LOAD");
    },
    decrementLoad(context){
      context.commit("DECREMENT_LOAD");
    },
  },
});