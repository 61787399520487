import { api } from "../services/api";
import router from "../router";
import { displayErrMsg } from "../utils/errors";

export default {
  namespaced: true,
  state: {
    contextNotificationRule: null,
    notificationRule: null,
    notificationRules: [],
    users: [],
  },
  mutations: {
    async GET_NOTIFICATION_RULE(state, { data }) {
      await api
        .get(
          `notifications/customer/${data.customerId}/rules?id=${data.notificationRuleId}`
        )
        .then((response) => {
          state.notificationRule = response.data[0];
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Regra de Notificação', "GET_NOTIFICATION_RULE");
        });
    },

    async GET_NOTIFICATION_RULES(state, customerId) {
      await api
        .get("notifications/customer/" + customerId + "/rules")
        .then((response) => {
          state.notificationRules = response.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Regra de Notificação', "GET_NOTIFICATION_RULES");
        });
    },

    async GET_USERS(state, { data }) {
      await api
        .get(`users/customer/${data.customerId}`)
        .then((response) => {
          state.users = response.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Usuário', "GET_USERS");
          router.push("/");
        });
    },

    async SAVE_NOTIFICATION_RULE(state, { data }) {
 

      console.log(state);
      await api
        .post("notifications/rules", data.notificationRule)
        .then(() => {
          displayErrMsg('GLOBAL_SUCCESS_ADD', 'Regra de Notificação', "SAVE_NOTIFICATION_RULE");
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Regra de Notificação', "SAVE_NOTIFICATION_RULE");
        });

      router.push({
        name: "customer-edit",
        params: router.currentRoute.params,
      });
    },

    async UPDATE_NOTIFICATION_RULE(state, { data }) {
      await api
        .put("notifications/rules", data.notificationRule)
        .then(() => {
          displayErrMsg('GLOBAL_SUCCESS_MODIFY', 'Regra de Notificação', "UPDATE_NOTIFICATION_RULE");
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Regra de Notificação', "UPDATE_NOTIFICATION_RULE");
        });
      router.push({
        name: "customer-edit",
        params: router.currentRoute.params,
      });
    },

    async DELETE_NOTIFICATION_RULES(state, { data }) {
      await api
        .delete(
          `notifications/customer/${data.customerId}/rules?${
            "id=" + data.notificationRules.join("&id=")
          }`
        )
        .then(() => {
          displayErrMsg('GLOBAL_SUCCESS_DELETE', 'Regra de Notificação', "DELETE_NOTIFICATION_RULES");
          state.notificationRules = state.notificationRules.filter(
            (rule) => !data.notificationRules.includes(rule.id)
          );
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Regra de Notificação', "DELETE_NOTIFICATION_RULES");
        });
    },
  },
  getters: {
    contextNotificationRule: (state) => state.contextNotificationRule,
    notificationRule: (state) => state.notificationRule,
    notificationRules: (state) => state.notificationRules,
    users: (state) => state.users,
  },
  actions: {
    getNotificationRule(context, data) {
      context.commit("GET_NOTIFICATION_RULE", { data });
    },
    getNotificationRules(context, customerId) {
      context.commit("GET_NOTIFICATION_RULES", customerId);
    },
    getUsers(context, data) {
      context.commit("GET_USERS", { data });
    },
    saveNotificationRule(context, data) {
      context.commit("SAVE_NOTIFICATION_RULE", { data });
    },
    updateNotificationRule(context, data) {
      context.commit("UPDATE_NOTIFICATION_RULE", { data });
    },
    deleteNotificationRule(context, data) {
      context.commit("DELETE_NOTIFICATION_RULES", { data });
    },
  },
};
