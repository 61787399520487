<template>
  <div id="login-body-div" class="login-body font">
    <div id="login-container-div" class="container-login">
      <div id="login-first-cont-div" class="content first-content">
        <div id="login-first-col-div" class="first-column">
          <img
            id="fpass-tw-logo"
            :src="require('../../assets/img/Logo3.png')"
            style="width: 50%"
          />
          <p id="fpass-description" class="description description-primary">
            (Seu ideal)
          </p>
          <div>
            <router-link to="/login" id="login-btn-a">
              <button id="login-btn-back" class="btn-login btn-login-primary">Voltar</button>
            </router-link>
          </div>
        </div>

        <div id="login-second-col-div" class="second-column">
          <h2 id="login-title-h2" class="title title-second">Recuperação de senha</h2>
          <p id="login-description-p" class="description description-second">
            Informe seu email cadastrados no sistema:
          </p>
          <form id="login-form-recover" class="form login-box mt-3 card" @submit="recoverPass">
            <label id="login-label-input" class="label-input" for="">
              <i id="fname" class="far fa-envelope icon"></i>
              <input
                id="fpass-input-email"
                required
                placeholder="Email"
                v-model="email"
                name="email"
                type="email"
                class="form-control formzinho"
                v-bind:style="
                    !valid_email
                      ? 'border: 1px solid red;'
                      : 'height: 45px; width: 100%'
                  "
                style="height: 45px; width: 100%"
              />
            </label>
            <button id="login-btn-send" class="btn-login btn-login-second">enviar</button>
            <div id="fpass-overlay-load" v-if="load" class="overlay text-center">
              <i id="fpass-load-spin" class="fas fa-sync-alt fa-spin"></i>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgotPass",
  data: function () {
    return {
      email: "",
    };
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    year: () => {
      const date = new Date();
      return date.getFullYear();
    },
    valid_email(){
      return this.$store.getters["login/valid_email"];
    }
  },
  methods: {
    recoverPass() {
      event.preventDefault();
      this.$store.dispatch("login/reset", {
        email: this.email,
      });
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap");
.card {
  box-shadow: none !important;
}
</style>
