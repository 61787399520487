<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1 class="title-I">Controle de licenças da plataforma</h1>
    </div>
    <div class="col-12 col-sm-12 p-0 col-md-12">
      <div class="card direct-chat direct-chat-primary">
        <div class="card-body">
          <div class="p-4 bg-white rounded">
            <div id="licenses" class="col-12 row">
              <div class="col-12 row-2">
                <p class="col-6 row" style="font-size: 18px">
                  Distribuição das licenças atualmente
                </p>
                <hr />
              </div>
              <div
                :class="'col-2 row-10 circle_title circle-' + license.role"
                v-for="license in licenses"
                :v-key="'circle-' + license.role"
                :key="'circle-' + license.role"
                :id="'extern-circle' + license.role"
              >
                <p :id="'title-' + license.role">{{ license.role }}</p>
                <div
                  :class="'circle-' + license.role"
                  style="width: 100%"
                  height="10rem"
                  :id="'id-circle-' + license.role"
                ></div>
              </div>
            </div>
            <div :v-can="['ADMIN']">
              <p class="col-6 row" style="font-size: 18px; margin-top: 30px">
                Editar número de licenças
              </p>
              <hr />
              <form
                id="edit-platform-licenses"
                class="col-12 row"
              >
                <GenInput
                  v-for="license in licenses_boards"
                  :key="license.role"
                  :label="license.role"
                  required="true"
                  col_lg="2"
                  :id="'platforminput-' + license.role"
                >
                  <input
                    type="number"
                    max="2147483647"
                    min="0"
                    :id="'input-' + license.role"
                    v-model="license.total_licenses"
                    class="form-control"
                  />
                </GenInput>

                <div style="padding: 3.1%" class="col-6 row">
                  <button
                    id="att_platform"
                    class="btn btn-primary px-5"
                    :disabled="load"
                    @click="updateLicenses"
                  >
                    <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import Modal from "../../components/Modal.vue";
import GenInput from "@/components/GenInput.vue";
import CircleProgress from "js-circle-progress";

export default {
  name: "EditPlatform",
  data() {
    return {
      breadcrumbList: [],
      licenses: [],
      licenses_circle: [],
      licenses_boards: [],
    };
  },
  created() {
    this.updateList();
    this.$store.dispatch("platform/getLicenses");
  },
  updated() {
    if (this.licenses.length > 0 && this.licenses_circle.length == 0) {
      this.licenses.sort((a, b) => this.sortFunc(a, b));
      this.licenses_boards.sort((a, b) => this.sortFunc(a, b));
      for (const element of this.licenses) {
        let t = new CircleProgress(".circle-" + element.role, {
          value: element.used_licenses,
          max: element.total_licenses,
          textFormat: "horizontal",
        });
        this.licenses_circle.push(t);
      }
    }
  },
  methods: {
    sortFunc(a, b) {
      if (a.role < b.role) {
        return -1;
      }
      if (a.role > b.role) {
        return 1;
      }
      return 0;
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    updateLicenses() {
      event.preventDefault();
      let lic_env = [];
      let form = document.getElementById("edit-platform-licenses");
      for (let license of this.licenses_boards) {
        if (!form.reportValidity()) {
          window.frontMsg("FRNT_NUM_RANGE_WARNING", {
            field: "Número de Licenças",
            numbercondition: "dentro do intervalo entre zero e o limite máximo",
          });
          return;
        }

        if (license.total_licenses === "" || license.total_licenses < 0) {
          window.frontMsg("FRNT_NUM_RANGE_WARNING", {
            field: "Número de Licenças",
            numbercondition: "dentro do intervalo entre zero e o limite máximo",
          });
          return;
        }
        lic_env.push({
          role: license.role,
          total_licenses: license.total_licenses,
        });
      }
      this.$store.dispatch("platform/updateLicenses", {
        licenses: lic_env,
      });
    },
  },
  watch: {
    $route() {
      this.updateList();
    },
    platform_licenses() {
      this.licenses = this.platform_licenses.filter((element) => {
        return element.total_licenses > 0;
      });
      this.licenses_boards = this.platform_licenses;
      localStorage.setItem(
        "platform_licenses_data",
        JSON.stringify(this.platform_licenses)
      );
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    platform_licenses() {
      return this.$store.getters["platform/platform_licenses"];
    },
  },
  components: {
    Breadcrumb,
    GenInput,
    Modal,
  },
};
</script>
<style lang="scss" scoped>
.circle_title {
  padding: 10px;
  text-align: center;
}
</style>
