import Vue from "vue";
import Router from "vue-router";

import auth from "./auth";
import hasRole from "./utils/hasRole";

import Login from "./views/Login/Login.vue";
import ForgotPass from "./views/Login/ForgotPassword.vue";
import ConfirmLogin from "./views/Login/ConfirmLogin.vue";
import ConfirmReset from "./views/Login/ConfirmReset.vue";
import FirstLogin from "./views/Login/FirstLogin.vue";

import User from "./views/User/Manager.vue";
import UserAdd from "./views/User/Add.vue";
import UserEdit from "./views/User/Edit.vue";
import NoFrontend from "./views/User/NoFrontend.vue";

import Customer from "./views/Customer/Manager.vue";
import CustomerAdd from "./views/Customer/Add.vue";
import CustomerEdit from "./views/Customer/Edit.vue";

import CustomerAddAsset from "./views/Asset/Add.vue";
import CustomerEditAsset from "./views/Asset/Edit.vue";
import CustomerImportAsset from "./views/Asset/Import.vue";

import CustomerAddContact from "./views/Contact/Add.vue";
import CustomerEditContact from "./views/Contact/Edit.vue";

import Group from "./views/Group/Manager.vue";
import GroupAdd from "./views/Group/Add.vue";
import GroupEdit from "./views/Group/Edit.vue";
import GroupConfig from "./views/Group/GroupConfig.vue";

import Dashboard from "./views/Dashboard/Index.vue";

import AddNotificationRule from "./views/NotificationRule/Add.vue";
import EditNotificationRule from "./views/NotificationRule/Edit.vue";

import Unauthorized from "./views/401.vue";
import LoadingPage from "./components/LoadingPage.vue";

import ManagerPlatform from "./views/Platform/Manager.vue";
import EditPlatform from "./views/Platform/Edit.vue";

Vue.use(Router);

function requireAuth(to, from, next) {
  if (!auth.loggedIn()) {
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
}

function requireRole(to, from, next) {
  to.matched.forEach((rec) => {
    if (rec.meta.requiredRole) {
      const can = hasRole(rec.meta.requiredRole);
      if (can) {
        next();
      } else {
        next("/401");
      }
    } else {
      next();
    }
  });
}

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/first-login",
      name: "first-login",
      component: FirstLogin,
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/confirm-login",
      name: "confirm-login",
      component: ConfirmLogin,
    },
    {
      path: "/NoFrontend",
      name: "NoFrontend",
      component: NoFrontend,
    },
    {
      path: "/recover-pass",
      name: "forgot-password",
      component: ForgotPass,
    },
    {
      path: "/confirm-reset",
      name: "reset-password",
      component: ConfirmReset,
    },
    {
      path: "/user",
      name: "user",
      component: User,
      beforeEnter: requireAuth,
    },
    {
      path: "/user/add",
      name: "user-add",
      component: UserAdd,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "user", label: "Usuários", link: "/user" },
          { name: "user-add", label: "Adicionar usuário" },
        ],
      },
    },
    {
      path: "/user/edit/:id?",
      name: "user-edit",
      component: UserEdit,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "user", label: "Usuários", link: "/user" },
          { name: "user-edit", label: "Editar usuário" },
        ],
      },
    },
    {
      path: "/customer",
      name: "customer",
      component: Customer,
      beforeEnter: requireAuth,
      meta: {
        requiredRole: [
          "ADMIN",
          "SYSADMIN",
          "MANAGER",
          "DASHBOARD",
          "FRONTDESK",
          "USER",
        ],
      },
    },
    {
      path: "/customer/add",
      name: "customer-add",
      component: CustomerAdd,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          { name: "customer-add", label: "Adicionar cliente" },
        ],
        requiredRole: ["ADMIN"],
      },
    },
    {
      path: "/customer/:customerId/edit",
      name: "customer-edit",
      component: CustomerEdit,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          { label: "Editar cliente" },
        ],
        requiredRole: [
          "ADMIN",
          "SYSADMIN",
          "MANAGER",
          "DASHBOARD",
          "FRONTDESK",
          "USER",
        ],
      },
    },
    {
      path: "/customer/:customerId/asset/add",
      name: "customer-asset-add",
      component: CustomerAddAsset,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Adicionar ativo" },
        ],
        requiredRole: ["ADMIN", "FRONTDESK", "SYSADMIN"],
      }
    },
    {
      path: "/customer/:customerId/asset/:assetId/edit",
      name: "customer-asset-edit",
      component: CustomerEditAsset,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Editar ativo" },
        ],
        requiredRole: ["ADMIN", "FRONTDESK", "SYSADMIN"],
      }
    },
    {
      /* import customer-asset-import */
      path: "/customer/:customerId/asset/import",
      name: "customer-asset-import",
      component: CustomerImportAsset,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Importar ativos" },
        ],
        requiredRole: ["ADMIN", "FRONTDESK", "SYSADMIN"],
      }
    },
    {
      path: "/customer/:customerId/contact/add",
      name: "customer-contact-add",
      component: CustomerAddContact,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Adicionar contato" },
        ],
        requiredRole: ["ADMIN", "SYSADMIN", "MANAGER"],
      },
    },
    {
      path: "/customer/:customerId/contact/:contactId/edit",
      name: "customer-contact-edit",
      component: CustomerEditContact,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Editar contato" },
        ],
        requiredRole: ["ADMIN", "SYSADMIN", "MANAGER", "FRONTDESK"],
      },
    },
    {
      path: "/group",
      name: "group",
      component: Group,
      beforeEnter: requireAuth,
    },
    {
      path: "/group/:groupName/edit",
      name: "group-edit",
      component: GroupEdit,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "group", label: "Grupos", link: "/group" },
          { label: "Editar grupo" },
        ],
        requiredRole: ["ADMIN", "SYSADMIN", "MANAGER"],
      },
    },
    {
      path: "/group/:groupName/auth",
      name: "group-config",
      component: GroupConfig,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "group", label: "Grupos", link: "/group" },
          { label: "Configurações de autenticação" },
        ],
        requiredRole: ["ADMIN"],
      },
    },
    {
      path: "/group/add",
      name: "group-add",
      component: GroupAdd,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "group", label: "Grupos", link: "/group" },
          { name: "group-add", label: "Adicionar grupo", link: "" },
        ],
        requiredRole: ["ADMIN"],
      },
    },

    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
      beforeEnter: requireAuth,
      meta: {
        requiredRole: ["DASHBOARD", "ADMIN", "SYSADMIN", "MANAGER", "EMPTY"],
      },
    },
    {
      path: "/customer/:customerId/notification-rule/add",
      name: "customer-add-notification-rule",
      component: AddNotificationRule,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Regras de notificação" },
        ],
        requiredRole: ["ADMIN", "SYSADMIN", "MANAGER"],
      },
    },
    {
      path: "/customer/:customerId/notification-rule/:notificationRuleId/edit",
      name: "customer-edit-notification-rule",
      component: EditNotificationRule,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Regras de notificação" },
        ],
        requiredRole: ["ADMIN", "SYSADMIN", "MANAGER"],
      },
    },
    {
      path: "/",
      name: "customer-home",
      component: Customer,
      beforeEnter: requireAuth,
    },
    {
      path: "/401",
      name: "Unauthorized",
      component: Unauthorized,
    },
    {
      path: "/loading",
      name: "Loading",
      component: LoadingPage,
    },
    {
      path: "/platform",
      name: "platform",
      component: ManagerPlatform,
      beforeEnter: requireAuth,
      meta: {
        requiredRole: ["ADMIN", "SYSADMIN"],
      },
    },
    {
      path: "/platform/edit",
      name: "edit-platform",
      component: EditPlatform,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [

          { name: "platform", label: "Licenças", link: "/platform" },

          { label: "Controle de licenças" },

      ],
        requiredRole: ["ADMIN"],
      },
    }
  ],
  scrollBehavior() {
    return window.scrollTo({ top: 0, behavior: "smooth" });
  },
});

router.beforeEach(requireRole);

export default router;
