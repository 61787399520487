var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"main-sidebar sidebar-light-navy elevation-4"},[_c('router-link',{staticClass:"brand-link",attrs:{"to":"/customer","id":"gotocustomer"}},[_c('img',{staticClass:"brand-image",staticStyle:{"opacity":"1"},attrs:{"src":require('../assets/img/Logo1.png'),"alt":"Logo empresa"}})]),_c('div',{staticClass:"sidebar"},[_c('div',{staticClass:"user-panel mt-3 pb-3 mb-3 d-flex"},[_vm._m(0),_c('div',{staticClass:"info"},[_c('a',{staticClass:"d-block",attrs:{"href":"#"}},[_c('b',[_vm._v(_vm._s(!_vm.name ? "Carregando..." : _vm.name))])]),_c('a',{staticClass:"text-primary",attrs:{"id":"link-logout","href":"#"},on:{"click":_vm.logout}},[_vm._v("Logout")])])]),_c('nav',{staticClass:"mt-2"},[_c('ul',{staticClass:"nav nav-pills nav-sidebar flex-column"},[_c('li',{directives:[{name:"can",rawName:"v-can",value:(['ADMIN', 'MANAGER', 'EMPTY', 'DASHBOARD', 'SYSADMIN']),expression:"['ADMIN', 'MANAGER', 'EMPTY', 'DASHBOARD', 'SYSADMIN']"}],staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"id":"dashboardsidebar","to":"/dashboard"}},[_c('i',{staticClass:"fas fa-chart-pie nav-icon"}),_c('p',[_vm._v("Dashboards")])])],1),_c('li',{directives:[{name:"can",rawName:"v-can",value:([
            'ADMIN',
            'SYSADMIN',
            'MANAGER',
            'DASHBOARD',
            'USER',
            'MOBILE',
            'DEVICE',
            'USRADMIN',
          ]),expression:"[\n            'ADMIN',\n            'SYSADMIN',\n            'MANAGER',\n            'DASHBOARD',\n            'USER',\n            'MOBILE',\n            'DEVICE',\n            'USRADMIN',\n          ]"}],staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"id":"usersidebar","to":"/user"}},[_c('i',{staticClass:"fas fa-user nav-icon"}),_c('p',[_vm._v("Usuários")])])],1),_c('li',{directives:[{name:"can",rawName:"v-can",value:([
            'ADMIN',
            'SYSADMIN',
            'MANAGER',
            'DASHBOARD',
            'FRONTDESK',
            'USER',
          ]),expression:"[\n            'ADMIN',\n            'SYSADMIN',\n            'MANAGER',\n            'DASHBOARD',\n            'FRONTDESK',\n            'USER',\n          ]"}],staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"id":"customersidebar","to":"/customer"}},[_c('i',{staticClass:"far fa-id-card nav-icon"}),_c('p',[_vm._v("Clientes")])])],1),_c('li',{directives:[{name:"can",rawName:"v-can",value:(['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER']),expression:"['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER']"}],staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"id":"groupssidebar","to":"/group"}},[_c('i',{staticClass:"fa fa-users nav-icon"}),_c('p',[_vm._v("Grupos")])])],1),_c('li',{directives:[{name:"can",rawName:"v-can",value:(['ADMIN']),expression:"['ADMIN']"}],staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"id":"platformsidebar","to":"/platform"}},[_c('i',{staticClass:"fa fa-solid fa-laptop-code nav-icon"}),_c('p',[_vm._v("Plataforma")])])],1)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image d-flex align-items-center"},[_c('i',{staticClass:"far fa-user",staticStyle:{"font-size":"36px"}})])
}]

export { render, staticRenderFns }