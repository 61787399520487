import { api } from "../services/api";
import router from "../router";
import hasRole from "../utils/hasRole";
import { displayErrMsg } from "../utils/errors";
const CryptoJS = require("crypto-js/core");
CryptoJS.AES = require("crypto-js/aes");

export default {
  namespaced: true,
  state: {
    access_token: "",
    refresh_token: "",
    roles: [],
    loggedIn: false,
    email: "",
    password: "",
    key: "",
    valid_email: true,
    bad_email: false,
  },

  mutations: {
    LOGGEDIN(state) {
      state.loggedIn =
        state.access_token && state.access_token !== "" ? true : false;
    },
    async LOGIN(state, { data }) {
      await api
        .post("/auth/enter", data)
        .then((response) => {
          this.state.email = data.email;
          this.state.password = data.password;
          this.state.key = data.key;
          this.dispatch("login/saveStorage", {
            data: response.data,
          });

          if (data.web_secret_token) {
            if (localStorage.getItem("rememberMe") === "true") {
              localStorage.setItem("web_secret_token", data.web_secret_token);
            } else {
              sessionStorage.setItem("web_secret_token", data.web_secret_token);
            }
          }
          state.loggedIn = true;

          if (
            response.data.access_token === "" &&
            response.data.access_token !== undefined &&
            response.data.roles === "['NOFRONTEND']"
          ) {
            router.push({ path: "/NoFrontend" });
          } else if (
            response.data.access_token === "" &&
            response.data.access_token !== undefined &&
            response.data.roles === "" &&
            !response.data.state.includes("THE_FIRST_ACCESS") &&
            !response.data.state.includes("EXPIRED_PASSWORD")
          ) {
            router.push({ path: "confirm-login" });
            if (
              response.data.message.includes(
                "Code has already been sent several times"
              )
            ) {
              window.toastr.warning(
                response.data.message,
                "Você já solicitou muitas vezes, aguarde ou use o código já recebido.",
                {
                  closeButton: true,
                }
              );
            } else {
              window.toastr.success(
                // response.data.message,
                `E-mail enviado para ${data.email}`,
                "Código enviado com sucesso",
                {
                  closeButton: true,
                }
              );
            }
          }
          if (response.data.state.includes("THE_FIRST_ACCESS") || response.data.state.includes("EXPIRED_PASSWORD")) {
            router.push({ path: "first-login" });

            let toastMsg = "";
            let toastTitle = "";
            if(response.data.state.includes("EXPIRED_PASSWORD")){
              toastMsg = "Sua senha expirou e deve ser redefinida.";
              toastTitle = "Senha expirada";
            }
            else {
              toastMsg = "É o seu primeiro acesso e uma nova senha deve ser definida.";
              toastTitle = "Primeiro acesso";
            }
            window.toastr.success(
              // response.data.message,
              toastMsg,
              toastTitle,
              {
                closeButton: true,
              }
            );
            
            return this.email;
          }
          if (response.data.state.includes("TWO_FACTOR_INIT")) {
            this.$router.push({ path: "confirm-login" });
          } else {
            if (response.data.roles !== "['NOFRONTEND']" && response.data.roles !== "['USER']" && response.data.roles !== "['DEVICE']" && response.data.roles !== "['MOBILE']" && response.data.roles !== "['USRADMIN']") {
              router.push({ path: "/customer" });
            } else if (response.data.roles === "['USER']" || response.data.roles === "['DEVICE']" || response.data.roles === "['MOBILE']" || response.data.roles === "['USRADMIN']" ) {
              router.push({ path: "/user" });
            }
          }
        })
        .catch((data) => {
          if (data.response.data.code == 401) {
            const apiMsg = data.response.data.message;
            let block =
              " Usuário bloqueado devido ao número de tentativas de login ultrapassadas. Contate o administrador da plataforma";

            if (apiMsg.includes("invalid-email-or-password")) {
              const apiMsgSplit = apiMsg.split("/");
              const firstPart = apiMsgSplit[0].split(" ");
              const firstNum = firstPart[firstPart.length-1];
              const secondNum = apiMsgSplit[1].substring(0, apiMsgSplit[1].length);
              // console.log(firstNum, secondNum);
              const cut = secondNum - firstNum;
              if(cut != 0){
                let aviso =
                  "Você tem mais " +
                  cut +
                  " tentativas antes do usuário ser bloqueado.";
                this.state.message = aviso;
  
                window.toastr["error"](
                  "Você tem mais " +
                    cut +
                    " tentativas antes do usuário ser bloqueado.",
                  "Erro",
  
                  (window.toastr.options = {
                    width: 650,
                    // position: toast-top-center,
                    closeButton: true,
                    showDuration: 300,
                    hideDuration: 10000,
                    newestOnTop: true,
                    debug: true,
                  })
                );
                return aviso;
              }
            }
            if (apiMsg.includes("authentication-attempts-exceeded")) {
              this.state.message = block;

              window.toastr.error(
                "Usuário bloqueado devido ao número de tentativas de login ultrapassadas. Contate o administrador da plataforma",
                "Erro",
                {
                  width: 650,
                  // position: toast-top-center,
                  closeButton: true,
                  showDuration: 300,
                  hideDuration: 10000,
                }
              );
              return block;
            }
            if (apiMsg.includes("inactive-user-by-admin")) {
              this.state.message = block;

              window.toastr.error(
                "Usuário não está ativo. Contate o administrador da plataforma",
                "Erro",
                {
                  width: 650,
                  // position: toast-top-center,
                  closeButton: true,
                  showDuration: 300,
                  hideDuration: 10000,
                }
              );
              return block;
            }
          }
        });
    },
    async FIRSTLOGIN(state, { data }) {
      this.state.password = "";
      state.bad_email = false;
      await api
        .post("/auth/set", data)
        .then((response) => {
          state.bad_email = false;
          this.dispatch("login/saveStorage", { data: response.data });

          router.push({ path: "login" });
        })
        .catch((err) => {
          if (err.response.data.code === 404) {
            state.bad_email = true;
            window.toastr.error(
              "E-mail ou senha inválidos",
              "E-mail",
            );
            this.data.new_password = "";
            this.data.old_password = "";
          }
          if(err.response.data.code === 401){
            state.bad_email = true;
          }
          if (err.response.data.code === 400) {
            data.new_password = "";

            window.toastr.error(
              "Preencha todas as requisições para a senha forte.",
              "Senha fraca"
            );
            this.data.new_password = "";
            this.data.old_password = "";
          }
          this.data.new_password = "";
          this.data.old_password = "";
        });
    },
    async CONFIRMLOGIN(state, { data }) {
      this.state.password = "";
      state.bad_email = false;
      await api.post("/auth/confirm", data).then((response) => {
        this.dispatch("login/saveStorage", { data: response.data });
        if (response.data.state.includes("THE_FIRST_ACCESS")) {
          router.push({ path: "first-login" });

          return this.email;
        }
        if (hasRole(["USER", "USRADMIN", "MOBILE", "DEVICE"])) {
          router.push({ path: "/user" });
        } else {
          router.push({ path: "/customer" });
        }
      });
    },
    async RESET(state, { data }) {

      await api.post("/auth/reset", data).then(() => {
        router.push({ path: "confirm-reset" });
        state.valid_email = true;
      })
      .catch((err) => {
        if (err.response.data.code === 404){
          state.valid_email = false;

          window.toastr.error("Insira um e-mail válido!", "E-mail inválido");

        }
      });
    },
    async CONFIRMRESET(state, { data }) {

      await api.post("/auth/reset/confirm", data).then(() => {
        router.push({ path: "login" });
      });
    },
    SAVESTORAGE(state, { data }) {
      if (data.access_token !== "") {
        state.access_token = data.access_token;
        state.refresh_token = data.refresh_token;
        state.roles = data.roles;
        if (localStorage.getItem("rememberMe") === "true") {
          localStorage.setItem("email", this.state.email);
          Object.keys(data).map((key) => {
            if(key == 'roles'){
              let encrypted = CryptoJS.AES.encrypt(`${data[key]}`, "LQcL5RAVPVn7y0PJ");
              localStorage.setItem(key, encrypted.toString());
            }
            else{
              localStorage.setItem(`${key}`, data[key]);
            }
          });
        } else {
          sessionStorage.setItem("email", this.state.email);
          
          Object.keys(data).map((key) => {
            if(key === 'roles'){
              let encrypted = CryptoJS.AES.encrypt(`${data[key]}`, "LQcL5RAVPVn7y0PJ");
              sessionStorage.setItem(key, encrypted.toString());
            }
            else{
              sessionStorage.setItem(`${key}`, data[key]);
            }
            
          });
        }
      }
    },
    async LOGOUT_API(state) {
      router.push({ path: "/loading" });
      await api.put("/auth/logout").finally(() => {
        const storage =
          localStorage.getItem("rememberMe") === "true"
            ? localStorage
            : sessionStorage;
        storage.removeItem("access_token");
        storage.removeItem("refresh_token");
        storage.removeItem("email");
        state.access_token = "";
        state.refresh_token = "";
        state.roles = "";

        window.location = "/login";
      });
    },
    async RESEND_CODE() {

      await api
        .post("/auth/enter", {
          email: this.state.email,
          password: this.state.password,
          web_secret_token: process.env.VUE_APP_WEB_SECRET_TOKEN,
        })
        .then((response) => {
          if (
            response.data.message.includes(
              "Code has already been sent several times"
            )
          ) {
            window.toastr.warning(
              response.data.message,
              "Você já solicitou muitas vezes, aguarde ou use o código já recebido.",
              {
                closeButton: true,
              }
            );
          } else {
            window.toastr.success(
              response.data.message,
              "Código reenviado com sucesso",
              {
                closeButton: true,
              }
            );
          }
        })
        .catch(async (err) => {
          if (err.response.data.code === 400) {
            await setTimeout(() => {
              window.toastr.warning(
                "É necessário preencher os dados de login novamente",
                "Ops"
              );
            }, 1500);
            router.push({ path: "login" });
          }
        });
    },
    async LOGIN_SAML(state, { data }) {
      let b64 = btoa(data.redirect_to + "login/");
      window.location.replace(data.redirect_to + "v0/auth/saml/sso?email=" + data.email);
    },
    async LOGOUT_SAML(state, { data }){
      router.push({ path: "/loading" });

      const storage =
          localStorage.getItem("rememberMe") === "true"
            ? localStorage
            : sessionStorage;
        storage.removeItem("access_token");
        storage.removeItem("refresh_token");
        storage.removeItem("email");
        state.access_token = "";
        state.refresh_token = "";
        state.roles = "";
      let b64 = btoa(data.redirect_to + "login/");
      window.location.replace(data.redirect_to + "v0/auth/saml/slo?email=" + data.email);
    },
  },
  getters: {
    access_token: (state) => state.access_token,
    refresh_token: (state) => state.refresh_token,
    email: (state) => state.email,
    password: (state) => state.password,
    roles: (state) => state.roles,
    loggedIn: (state) => state.loggedIn,
    key: (state) => state.key,
    bad_email: (state) => state.bad_email,
    valid_email: (state) => state.valid_email,
  },
  actions: {
    login(context, data) {
      context.commit("LOGIN", { data });
    },
    confirmLogin(context, data) {
      context.commit("CONFIRMLOGIN", { data });
    },
    firstlogin(context, data) {
      context.commit("FIRSTLOGIN", { data });
    },
    reset(context, data) {
      context.commit("RESET", { data });
    },
    confirmReset(context, data) {
      context.commit("CONFIRMRESET", { data });
    },
    saveStorage(context, { data }) {
      context.commit("SAVESTORAGE", { data });
    },
    replaceAccessToken(context) {
      context.commit("REPLACE_ACCESS_TOKEN");
    },
    logoutCache(context) {
      const storage =
        localStorage.getItem("rememberMe") === "true"
          ? localStorage
          : sessionStorage;
      storage.removeItem("access_token");
      storage.removeItem("refresh_token");
      storage.removeItem("email");
      context.access_token = "";
      context.refresh_token = "";
      context.roles = "";
      window.location = "/login";
    },
    logout(context) {
      context.commit("LOGOUT_API");
    },
    resend(context) {
      context.commit("RESEND_CODE");
    },
    loginSAML(context, data) {
      context.commit("LOGIN_SAML", { data });
    },
    logoutSAML(context, data) {
      context.commit("LOGOUT_SAML", { data });
    }
  },
};