import { api } from "../services/api";
import router from "../router";
import { displayErrMsg } from "../utils/errors";

export default {
  namespaced: true,
  state: {
    selectedAsset: null,
    assets: [],
    successAssets: [],
    badAssets: [],
    maintenanceHistory: [],
  },

  mutations: {
    async GET_ASSET(state, { data }) {
      await api.get("assets/" + data.assetId).then((res) => {
        state.selectedAsset = res.data;
      });
    },
    async GET_ASSETS(state, { data }) {
      await api
        .get("customers/" + data.customer_id + "/assets")
        .then((res) => {
          state.assets = res.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Ativo", "GET_ASSETS");
        });
    },
    async ADD_ASSET(state, { data }) {
      await api
        .post("customers/" + data.customerId + "/assets", {
          assets: data.assets,
        })
        .then((res) => {
          displayErrMsg("GLOBAL_SUCCESS_ADD", "Ativo", "ADD_ASSET");
          state.assets.push(res.data);
          router.push({
            name: "customer-edit",
            params: router.currentRoute.params,
          });
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Ativo", "ADD_ASSET");
        });
    },
    async SAVE_LIST_ASSET(state, { data }) {
      /* /v0/customers/{customer_id}/assets */
      state.successAssets = [];
      state.badAssets = [];
      await api
        .post("customers/" + data.customer_id + "/assets", {
          assets: data.assets,
        })
        .then((res) => {
          displayErrMsg("GLOBAL_SUCCESS_ADD", "Ativo", "SAVE_LIST_ASSET"); 
          
          res.data.forEach(asset => {
            state.successAssets.push(asset);
            state.assets.push(asset);
          });
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Ativo", "SAVE_LIST_ASSET");
        });
    },
    async UPDATE_ASSET(state, { data }) {
      /* /v0/customers/{customer_id}/assets */
      await api
        .put("customers/" + data.customerId + "/assets", data.asset)
        .then(() => {
          displayErrMsg("GLOBAL_SUCCESS_MODIFY", "Ativo", "UPDATE_ASSET");
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Ativo", "UPDATE_ASSET");
        });
      router.push({
        name: "customer-edit",
        params: router.currentRoute.params,
      });
    },

    async DELETE_ASSET(state, { data }) {
      /* ​/v0​/assets​/{id}/ */
      await api
        .delete("assets/" + data.id)
        .then(() => {
          displayErrMsg("GLOBAL_SUCCESS_DELETE", "Ativo", "DELETE_ASSET");
          state.assets = state.assets.filter((el) => el.id !== data.id);
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Ativo", "DELETE_ASSET");
        });
    },

    async GET_MAINTENANCE_HISTORY(state, { data }) {
      /* /v0/assets/{asset_id}/maintenance */
      await api
        .get("assets/" + data.assetId + "/maintenance")
        .then((res) => {
          state.maintenanceHistory = res.data;
        })
        .catch((err) => {
          displayErrMsg(
            err.response.data.code,
            "Ativo",
            "GET_MAINTENANCE_HISTORY"
          );
        });
    },

    async ADD_MAINTENANCE_HISTORY(state, { data }) {
      await api
        .post("assets/" + data.assetId + "/maintenance", {maintenances: data.maintenances})
        .then((res) => {
          displayErrMsg(
            "GLOBAL_SUCCESS_ADD",
            "Ativo",
            "ADD_MAINTENANCE_HISTORY"
          );
          state.maintenanceHistory = state.maintenanceHistory.concat(res.data);
        })
        .catch((err) => {
          displayErrMsg(
            err.response.data.code,
            "Ativo",
            "ADD_MAINTENANCE_HISTORY"
          );
        });
    },
  },
  getters: {
    selectedAsset: (state) => state.selectedAsset,
    assets: (state) => state.assets,
    successAssets: (state) => state.successAssets,
    maintenanceHistory: (state) => state.maintenanceHistory,
  },
  actions: {
    getAsset(context, data) {
      context.commit("GET_ASSET", { data });
    },
    getAssets(context, data) {
      context.commit("GET_ASSETS", { data });
    },
    updateAsset(context, data) {
      context.commit("UPDATE_ASSET", { data });
    },
    deleteAsset(context, data) {
      context.commit("DELETE_ASSET", { data });
    },
    addAsset(context, data) {
      context.commit("ADD_ASSET", { data });
    },
    saveListAsset(context, data) {
      context.commit("SAVE_LIST_ASSET", { data });
    },
    getMaintenanceHistory(context, data) {
      context.commit("GET_MAINTENANCE_HISTORY", { data });
    },
    addMaintenanceHistory(context, data) {
      context.commit("ADD_MAINTENANCE_HISTORY", { data });
    },
  },
};
