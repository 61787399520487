<template>
  <div v-can="['ADMIN', 'SYSADMIN']">
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1 class="title-I">Adicionar Ativo</h1>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card direct-chat direct-chat-primary employee">
          <form @submit="saveAsset">
            <div class="card-body">
              <div class="pt-4 pr-4 pb-2 pl-4 bg-white rounded">
                <div class="row">
                  <GenInput col_lg="6" label="Nome" required="true">
                    <input
                      v-model="asset.name"
                      required
                      type="text"
                      class="form-control"
                      placeholder="Nome do Ativo"
                      id="assetName"
                      maxlength="64"
                      @change="verifyErrors('header.name')"
                      v-bind:style="
                        assetError.name.error
                          ? 'border-color: red'
                          : 'border-color: #ced4da'
                      "
                    />
                  </GenInput>
                  <GenInput label="Número de série" required="true">
                    <input
                      v-model="asset.serial_number"
                      type="text"
                      class="form-control"
                      placeholder="Número de série do Ativo"
                      id="assetSerial"
                      maxlength="128"
                      required="true"
                      @change="verifyErrors('header.serial_number')"
                      v-bind:style="
                        assetError.serial_number.error
                          ? 'border-color: red'
                          : 'border-color: #ced4da'
                      "
                    />
                  </GenInput>
                </div>
                <div class="row">
                  <GenInput label="Identificador Interno" required="true">
                    <i
                      title="Identificador Interno - SKU ou Número de patrimônio"
                      class="fas fa-question-circle"
                      style="margin-left: 5px"
                    >
                    </i>
                    <input
                      v-model="asset.internal_id"
                      type="text"
                      class="form-control"
                      placeholder="Número Identificador do Ativo"
                      id="assetInternal"
                      maxlength="32"
                      required="true"
                      @change="verifyErrors('header.internal_id')"
                      v-bind:style="
                        assetError.internal_id.error
                          ? 'border-color: red'
                          : 'border-color: #ced4da'
                      "
                    />
                  </GenInput>
                  <GenInput label="Custo de Aquisição">
                    <b-form-input
                      v-model="
                        asset.acquisition_cost
                      "
                      type="number"
                      min="0"
                      step=".01"
                      class="form-control"
                      placeholder="Custo de Compra do Ativo"
                      id="assetAcquisitionCost"
                      max="10000000000"
                      v-bind:style="
                        assetError.acquisition_cost
                          .error
                          ? 'border-color: red'
                          : 'border-color: #ced4da'
                      "
                      @change="
                        verifyErrors(
                          'financial_data.acquisition_cost'
                        )
                      "
                    />
                  </GenInput>
                </div>
                <div class="row">
                  <GenInput label="Data de Fabricação">
                    <input
                      v-model="asset.fabrication_date"
                      type="date"
                      class="form-control"
                      placeholder="Data de Fabricação"
                      id="assetManufacturingDate"
                      :min="'1900-01-01'"
                      :max="'2099-12-31'"
                      @focusout="verifyErrors('header.acquisition_date')"
                      v-bind:style="
                        assetError.manufacturing_date.error
                          ? 'border-color: red'
                          : 'border-color: #ced4da'
                      "
                    />
                  </GenInput>
                  <GenInput label="Data de Aquisição">
                    <input
                      v-model="asset.acquisition_date"
                      type="date"
                      class="form-control"
                      placeholder="Data de Aquisição"
                      id="assetAcquisitionDate"
                      :min="'1900-01-01'"
                      :max="'2099-12-31'"
                      @focusout="verifyErrors('header.acquisition_date')"
                      v-bind:style="
                        assetError.acquisition_date.error
                          ? 'border-color: red'
                          : 'border-color: #ced4da'
                      "
                    />
                  </GenInput>
                </div>
                <div class="row">
                  <GenInput label="Categoria">
                    <b-form-input
                      list="categoryList"
                      placeholder="Categoria do Ativo"
                      class="form-control noArrow"
                      id="assetCategory"
                      autocomplete="off"
                      type="text"
                      v-model="asset.category"
                      maxlength="64"
                    ></b-form-input>
                  </GenInput>
                  <GenInput label="Subcategoria">
                    <b-form-input
                      list="subcategoryList"
                      placeholder="Subcategoria do Ativo"
                      class="form-control noArrow"
                      id="assetSubcategory"
                      autocomplete="off"
                      type="text"
                      v-model="asset.subcategory"
                      maxlength="64"
                    ></b-form-input>
                  </GenInput>
                </div>
                <div class="row">
                  <GenInput col_lg="6" label="Geolocalização - Latitude">
                    <input
                      v-model="asset.latitude"
                      type="text"
                      class="form-control"
                      placeholder="Latitude"
                      id="assetManufacturingDate"
                    />
                  </GenInput>
                  <GenInput col_lg="6" label="Geolocalização - Longitude">
                    <input
                      v-model="asset.longitude"
                      type="text"
                      class="form-control"
                      placeholder="Longitude"
                      id="assetManufacturingDate"
                    />
                  </GenInput>
                </div>
                <div class="row mb-2">
                  <GenInput col_lg="12" label="Descrição do ativo">
                    <b-form-textarea
                      v-model="asset.description"
                      id="assetDescription"
                      class="form-control"
                      placeholder="Descrição do ativo"
                      rows="3"
                      max-rows="8"
                      maxlength="256"
                      no-auto-shrink
                    ></b-form-textarea>
                  </GenInput>
                </div>
                <div class="row pb-3 pl-2">
                  <button
                    id="saveAsset"
                    class="btn btn-primary px-5"
                    :disabled="load"
                  >
                    <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import {
  formatDate,
  formatDateAPI,
  formatNumber,
} from "@/utils/format-fields.js";
import "./style.scss";
import GenInput from "@/components/GenInput.vue";

export default {
  name: "AddAsset",
  data: function () {
    return {
      asset: {
        internal_id: null,
        acquisition_date: null,
        fabrication_date: null,
        acquisition_cost: null,
        latitude: null,
        longitude: null,
        category: "",
        description: "",
        name: null,
        serial_number: null,
        subcategory: "",
      },
      assetError: {
        error: false,
        name: {
          error: false,
          label: "Nome",
          type: "string",
        },
        serial_number: {
          error: false,
          label: "Número de Série",
          type: "string",
        },
        internal_id: {
          error: false,
          label: "Identificador Interno",
          type: "string",
        },
        acquisition_date: {
          error: false,
          label: "Data de Aquisição",
          type: "date",
        },
        manufacturing_date: {
          error: false,
          label: "Data de Aquisição",
          type: "date",
        },
        acquisition_cost: {
          error: false,
          label: "Custo de Aquisição",
          type: "number",
          max: 10000000000,
        },
      },
    };
  },

  mounted() {
    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });
    }
    this.updateList();
  },
  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if (this.customer) {
        this.breadcrumbList[1].label = this.customer.company_name;
      }
    },
    saveAsset() {
      event.preventDefault();
      let sendAsset = this.asset;
      if (sendAsset.acquisition_date) {
        sendAsset.acquisition_date = formatDateAPI(sendAsset.acquisition_date);
      }
      if (sendAsset.fabrication_date) {
        sendAsset.fabrication_date = formatDateAPI(
          sendAsset.fabrication_date
        );
      }
      this.$store.dispatch("asset/addAsset", {
        assets: [sendAsset],
        customerId: this.$route.params.customerId,
      });
    },
    changeTab(newTab) {
      this.tabSelected = newTab;
    },
    verifyErrors(key) {
      let assetContent = (assetData, objKey) => {
        const assetKey = objKey.split(".");
        let data = assetData;

        for (let i of assetKey) {
          data = data[i];
        }

        return data;
      };

      let changeErrorStatus = (status) => {
        const assetKey = key.split(".");

        if (assetKey.length === 1) {
          this.assetError[assetKey[0]].error = status;
        } else if (assetKey.length === 2) {
          this.assetError[assetKey[0]][assetKey[1]].error = status;
        } else if (assetKey.length === 3) {
          this.assetError[assetKey[0]][assetKey[1]][assetKey[2]].error = status;
        }
      };

      let objKey = key.includes("header.") ? key.replace("header.", "") : key;
      let content = assetContent(this.asset, objKey);
      let errorObj = assetContent(this.assetError, key);
      let error = false;

      if (errorObj.type !== "string" && content) {
        if (
          errorObj.type === "number" &&
          (content < 0 || content > errorObj.max)
        ) {
          error = true;
          window.frontMsg("FRNT_NUM_RANGE_WARNING", {
            field: errorObj.label,
            numbercondition: `entre 0 e ${formatNumber(errorObj.max)}`,
          });
        } else if (errorObj.type === "date") {
          let inputDate = new Date(content);
          let minDate = new Date("1900-01-01");
          let maxDate = new Date("2099-12-31");

          if (
            inputDate < minDate ||
            inputDate > maxDate ||
            inputDate == "Invalid Date"
          ) {
            error = true;
            window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
              field: errorObj.label,
              extra: "Insira uma data entre 01/01/1900 e 31/12/2099.",
            });
          }
        }
      } else if (
        errorObj.type === "string" &&
        (!content || !content?.toString().trim())
      ) {
        error = true;
        window.frontMsg("FRNT_EMPTY_WARNING", { field: errorObj.label });
      }

      changeErrorStatus(error);
    },
  },
  watch: {
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
  },
  components: {
    Breadcrumb,
    GenInput,
  },
};
</script>

<style>
.datalist-wrapper {
  appearance: none;
  position: relative;
}
.datalist-wrapper::after {
  content: "⌄";
  font-size: 1.2rem;
  pointer-events: none;
  /*default right/bottom doenst align select field arrow, therefore this is necessary*/
  bottom: 5px;
  right: 0px;
  position: absolute;
}
.noArrow::-webkit-calendar-picker-indicator {
  opacity: 0;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: center;
  caption-side: bottom;
  background-color: rgba(215, 198, 248, 0.2);
  border: 2px solid rgba(215, 198, 248, 0.2);
  border-radius: 2px;
}
</style>
