<template>
  <div id="content"><img src="@/assets/img/Logo2.png" /></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#content {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: white;
}

#content img {
  position: absolute;
  right: 30px;
  bottom: 30px;
  margin-left: -100px;
  margin-top: -100px;
  animation: animacao 1s infinite;
}

@keyframes animacao {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
