<template>
  <div id="conflog-body" class="login-body font">
    <div id="conflog-container-log" class="container-login">
      <div id="conflog-first-con" class="content first-content">
        <div id="conflog-first-col" class="first-column">
          <img
            id="conflog-logo" :src="require('../../assets/img/Logo3.png')"
            style="width: 50%"
          />
          <p id="conflog-desc-p" class="description description-primary mt-3">
            (Seu ideal)
          </p>
          <div>
            <router-link to="/login" id="back-back">
              <button id="conflog-back-but" class="btn-login btn-login-primary">Voltar</button>
            </router-link>
          </div>
        </div>
        <div id="conflog-second-col" class="second-column">
          <h2 id="conflog-title-conf" class="title title-second">Confirmar login</h2>
          <p id="conflog-desc-p2" class="description description-second">
            Uma mensagem com um código de verificação <br />
            <br />
            foi enviada por email e SMS.
          </p>
          <form id="conflog-conf-form" @submit="confirm" method="post" class="form card">
            <label id="conflog-label-for-form" for="fname" class="mt-3"
              >Insira o código para continuar:
              <label id="conflog-label-for-inp" class="label-input" for="">
                <i id="fname" class="fas fa-envelope-open-text icon"></i>
                <input
                  id="conflog-code-in"
                  required
                  name="code"
                  type="text"
                  class="form-control formzinho"
                  placeholder="Código"
                  v-model="code"
                  style="height: 45px; width: 100%"
                />
              </label>
            </label>
            <div id="conflog-body" class="py-2 pr-4">
              <span
                id="code-resend"
                class="float-right btn-link"
                @click="resend"
              >
                Reenviar código
              </span>
            </div>
            <button id="conflog-enter-but" class="btn-login btn-login-pass"
              style="color: white" :disabled="load">Entrar</button>

            <div id="conflog-load-over" v-if="load" class="overlay">
              <i id="conflog-load-spin" class="fas fa-sync-alt fa-spin"></i>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmLogin",
  data: function () {
    return {
      code: "",
    };
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    year: () => {
      const date = new Date();
      return date.getFullYear();
    },
  },
  methods: {
    resend() {
      event.preventDefault();
      this.$store.dispatch("login/resend");
    },
    confirm() {
      event.preventDefault();
      this.$store.dispatch("login/confirmLogin", {
        code: this.code,
      });
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap");
.card {
  box-shadow: none !important;
}
#code-resend {
  cursor: pointer;
}
</style>
